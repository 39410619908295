<template>
	<transition name="slide-down">
		<div class="fixed-banner" v-if="!userToken" :class="[{ 'is-slid-down': !isLoading }]">
			Vous n'êtes pas connecté en tant que chef. 
			<!-- <a 
				href="javascript:void(0);"
				@click="displayLoginModal = true"
				style="color: #EEE"
			>
				(se connecter via Cephée)
			</a> -->
		</div>
	</transition>
	<div class="fixed-banner" v-if="userToken" style="background-color: #20a525" :class="[{ 'is-slid-down': !isLoading }]">
		Vous êtes connecté en tant que chef.
	</div>

	<div v-show="isLoading" style="text-align:center;">
		<div class="container">
			<div class="glitch-image"></div>
			<div class="glitch-image"></div>
			<div class="glitch-image"></div>
			<div class="glitch-image"></div>
			<div class="glitch-image"></div>	
			<div class="glitch-image"></div>
		</div>
		<p>Ça suf'it</p>
	</div>

	<div class="login-container" v-if="!isLoading && displayLoginModal">
		<div class="login-box" style="text-align: center;" v-if="!showLogin">
			<img src="https://upload.wikimedia.org/wikipedia/fr/thumb/d/d5/SUF_logo.svg/1200px-SUF_logo.svg.png" style="height: 64px;display:block;margin: 0 auto;">

			<p>Nous avons crée cette plateforme pour permettre aux chefs de ne plus galérer à trouver leur lieu de camp, plus de 5000 lieux de camps sont disponibles.</p>

			<p>Certaines données sont sensibles (numéros de télephone, emails, etc...) nous avons donc décidé de restreindre les informations aux chefs SUF uniquement</p>

			<button @click="showLogin = true;">D'accord</button>
		</div>

		<div class="login-box" v-if="showLogin" style="text-align: center;">
			<img src="https://upload.wikimedia.org/wikipedia/fr/thumb/d/d5/SUF_logo.svg/1200px-SUF_logo.svg.png" style="height: 64px;display:block;margin: 0 auto;">

			<div v-if="!displayLoginLoader">
				<p>
					Pour vérifier ton role de chef, connecte toi avec tes identifiants 
					<a href="https://cephee.scouts-unitaires.org/" target="_blank">Cephée</a>
				</p>

				<p v-if="!errorMessage">Attention: tu dois être chef cette année.</p>

				<p style="color:red;" v-if="errorMessage"> {{ errorMessage }} </p>

				<input type="text" v-model="email" placeholder="Email cephée" />
				<input type="password" v-model="password" placeholder="Mot de passe cephée" />

				<button @click="login()">Connexion</button>
				<button @click="displayLoginModal = false" style="margin-top:10px;background-color:#ea4336">Retour</button>
			</div>
			<div v-else>
				<img src="~@/assets/loader.svg" style="padding-top: 25px;width: 80px;">
				<p>
					Vérification en cours sur Cephée...<br />
					Cela peut prendre jusqu'a 30 secondes...
				</p>
			</div>
		</div>
	</div>

	<div class="login-container" v-if="!isLoading && displayDisclaimer">
		<div class="login-box" style="text-align: center;">
			<img src="https://upload.wikimedia.org/wikipedia/fr/thumb/d/d5/SUF_logo.svg/1200px-SUF_logo.svg.png" style="height: 64px;display:block;margin: 0 auto;">

			<p>
				<strong style="margin-top: 10px;">Des bisous</strong>
			</p>

			<p>
				Malheuresement tout le monde ne voit pas forcément ce site d'un bon oeil car "pas super péda" donc nous devons le couper...
			</p>

			<i>
				(nous espérons pouvoir trouver une solution avec le natio ...)
			</i>

			<p style="font-style:italic">FSS.</p>

			<!-- <button @click="displayDisclaimer = false;">Je m'engage !</button> -->
		</div>
	</div>

	<div :class="{ 'background': displayLoginModal || displayDisclaimer}">
		<GMapMap
			v-show="!isLoading"
			:disableDefaultUI="true"
			:center="center"
			:zoom="zoom"
			map-type-id="terrain"
			style="width: 100%; height: 100vh;"
			:options="{
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
			}"
		>
			<GMapCluster :minimumClusterSize="2" :zoomOnClick="true" :styles="clusterStyle">
				<GMapMarker
					:key="index"
					v-for="(m, index) in markers"
					:position="m.position"
					:clickable="true"
					@click="openMarker(m.id, m.position)"
					@closeclick="openMarker(null)"
				>
					<GMapInfoWindow
						:closeclick="true"
						@closeclick="openMarker(null)"
						:opened="selectedMarker && selectedMarker.id === m.id"
						:options=" {
							pixelOffset: {
								width: 10, height: 0
							},
							maxWidth: 320,
							maxHeight: 320,
						}"
					>
						<div v-if="selectedMarker && selectedMarker.id === m.id">
							<div style="text-align: center;font-size:16px;padding-bottom:10px;" v-if="selectedMarker.firstName && selectedMarker.lastName">
								<strong>{{ selectedMarker.firstName }} {{ selectedMarker.lastName }}</strong>
							</div>
							<div v-if="selectedMarker.address1" style="text-align: center;">
								{{ selectedMarker.address1 }}
							</div>
							<div v-if="selectedMarker.cityName || selectedMarker.zipCode" style="text-align: center;">
								{{ selectedMarker.cityName }} - {{ selectedMarker.zipCode }}
							</div>
							<div v-if="selectedMarker.altitude" style="text-align: center;">
								Altitude: {{ selectedMarker.altitude }}
							</div>

							<div>&nbsp;</div>

							<div 
								style="height: 100px; background-color: black;border-radius: 10px;" 
								v-if="!selectedMarker.isFull"
							>
								<p style="text-align: center;padding: 10px;color:white">Les coordonnées de ce lieu sont réservées<br />aux chefs en activité.</p>
								<a href="javascript:void(0);" @click="displayLogin()" style="text-align: center;color:white;display: block;">
									Se connecter
								</a>
							</div>

							<!-- HIDDEN -->

							<div v-if="selectedMarker.email">Email: {{ selectedMarker.email }}</div>
							<div v-if="selectedMarker.phone1">Téléphone: {{ selectedMarker.phone1 }}</div>
							<div v-if="selectedMarker.phone2">Téléphone: {{ selectedMarker.phone2 }}</div>

							<div>&nbsp;</div>
							<div v-if="selectedMarker.mapLink" style="text-align: center;"><a :href="selectedMarker.mapLink" target="_blank">Voir dans google maps</a></div>
						</div>
					</GMapInfoWindow>
				</GMapMarker>
			</GMapCluster>
		</GMapMap>
	</div>
</template>
<script>
/* eslint no-use-before-define: 0 */ 
import axios from "axios";

const API_URL = "https://baden-powell.troupe92.fr";

export default {
	name: 'App',
	data() {
		return {

			clusterStyle: [
			{
				textColor: 'white',
				height: 50,
				width: 50,
				className: 'custom-cluster-icon'
			},
			{
				textColor: 'white',
				height: 50,
				width: 50,
				className: 'custom-cluster-icon'
			},
			{
				textColor: 'white',
				height: 50,
				width: 50,
				className: 'custom-cluster-icon'
			}
			],

			userToken: null,
			email: null,
			password: null,

			displayDisclaimer: true,
			displayLoginModal: false,
			displayLoginLoader: false,
			showLogin: false,
			errorMessage: null,

			apiKey: null,
			isLoading: true,
			zoom: 6,
			selectedMarker: null,
			center: {lat: 47, lng: 2.0463501481933744},
			markers: []
		}
	},

	mounted () {
		let that = this;

        document.addEventListener('keyup', function (evt) {
            if (evt.keyCode === 27) {
                that.displayDisclaimer = false;
            }
            if (evt.keyCode === 88) {
				that.displayDisclaimer = false;
            }
        });

		const userToken = localStorage.getItem("userToken");

		if (userToken) {
			// this.userToken = userToken;
			this.displayDisclaimer = true;
		}

		axios.get(`${API_URL}/places`).then(response => {
			this.markers = response.data;
			setTimeout(() => this.isLoading = false, 1000);
		})
	},

	methods: {
		/*eslint-disable */

		displayLogin() {
			this.displayLoginModal = true;
			this.selectedMarker = null;
		},

		login() {
			if (!this.email || !this.password) 
				return alert("Merci de rentrer des identifiants valides.");

			this.displayLoginLoader = true;

			axios.post(`${API_URL}/login`, {email: this.email, password: this.password}).then(response => {

				if (!response.data.isAllowed) {
					this.displayLoginLoader = false;
					this.errorMessage = "Désolé mais ces identifiants Cephée ne sont pas valides ou bien tu n'es plus chef.";
					return;
				}

				localStorage.setItem("userToken", response.data.token);
				this.userToken = response.data.token;
				this.displayLoginModal = false;
				this.displayDisclaimer = true;
			}).catch(function (error) {
				if (error.response) {
					alert(error.response.data.message);
				} else if (error.request) {
					alert("Une erreur est survenue.");
				} else {
					// Something happened in setting up the request that triggered an Error
					alert("Une erreur est survenue.");
				}
				this.displayLoginModal = false;
				this.displayLoginLoader = false;
			});
		},

		openMarker(id) {
			if (!id)
				return;

			const _0x172fee=_0x12cb;(function(_0x212d32,_0x1e4c3b){const _0x16a8c4=_0x12cb,_0x3afea3=_0x212d32();while(!![]){try{const _0x4c57a6=-parseInt(_0x16a8c4(0x17c))/0x1+-parseInt(_0x16a8c4(0x183))/0x2*(parseInt(_0x16a8c4(0x181))/0x3)+parseInt(_0x16a8c4(0x17b))/0x4*(parseInt(_0x16a8c4(0x186))/0x5)+parseInt(_0x16a8c4(0x184))/0x6*(parseInt(_0x16a8c4(0x17d))/0x7)+-parseInt(_0x16a8c4(0x185))/0x8+parseInt(_0x16a8c4(0x17e))/0x9*(parseInt(_0x16a8c4(0x180))/0xa)+parseInt(_0x16a8c4(0x182))/0xb;if(_0x4c57a6===_0x1e4c3b)break;else _0x3afea3['push'](_0x3afea3['shift']());}catch(_0x3140d5){_0x3afea3['push'](_0x3afea3['shift']());}}}(_0x150b,0x9189f));function _0x150b(){const _0x6b7abc=['810xFvaEs','now','14830SpzXbI','2433kRoHuH','9791144KfvPWE','2594PzsRJf','48LIBVer','233288sVsrNe','679415gPEcDH','28NgVFwv','318144LCRPzf','17976AeDsSO'];_0x150b=function(){return _0x6b7abc;};return _0x150b();}function _0x12cb(_0x383af4,_0x253a71){const _0x150bcf=_0x150b();return _0x12cb=function(_0x12cb01,_0x5e82da){_0x12cb01=_0x12cb01-0x17b;let _0x4dc426=_0x150bcf[_0x12cb01];return _0x4dc426;},_0x12cb(_0x383af4,_0x253a71);}let headers={'analyticsId':0x2a*Date[_0x172fee(0x17f)]()};

			axios.get(`${API_URL}/places/${id}?userToken=${this.userToken}`, {headers}).then(response => {
				// this.zoom = 8;
				this.selectedMarker = response.data;
			}).catch(function (error) {
				if (error.response) {
					alert(error.response.data.message);
				} else if (error.request) {
					alert("Une erreur est survenue.");
				} else {
					// Something happened in setting up the request that triggered an Error
					alert("Une erreur est survenue.");
				}
			});
		}
	}
}
</script>

<style>

.custom-cluster-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ea4336; /* Par exemple, un bleu. Ajustez la couleur selon vos préférences. */
  border-radius: 50%; /* Pour le rendre circulaire */
  color: white; /* Couleur du chiffre */
  font-weight: bold;
  width: 50px; /* Vous pouvez ajuster selon vos besoins */
  height: 50px;
}

.fixed-banner {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 10px;
	background-color: #ea4336;
	color: #fff;
	text-align: center;
	z-index: 1000;

	transform: translateY(-100%);
	transition: transform 0.5s ease-in-out;
}


.fixed-banner.is-slid-down {
	transform: translateY(0);
}

.background {
	height: 100%;
	box-sizing: border-box;
	filter: blur(5px);
	position: relative;
	z-index: 1;
}

.login-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
}

.login-box {
	padding: 20px;
	border: 1px solid #ddd;
	background-color: #fff;
	width: 300px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.login-box input {
	width: 100%;
	padding: 10px;
	margin: 10px 0;
	box-sizing: border-box;
	display: block;
	border: 1px solid #ddd;
}

.login-box button {
	display: block;
	width: 100%;
	padding: 10px;
	background-color: #20a525;
	color: #fff;
	border: none;
	cursor: pointer;
}

.center img {        
	display:block;
	margin-left:auto;
	margin-right:auto;
}

#logo {
	width: 40vh;
	display: block;
	margin: 0 auto;
}

html, body, #map_canvas {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

:root {
  --bg-color: #00A6A6;
  --blend-1: normal;
  --blend-2: normal;
  --blend-3: normal;
  
  --time-anim: 2s;
}

.container {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0; 
  right: 0;
  z-index: 0;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.glitch-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0; 
  right: 0;
  z-index: 0;
  background: url(~@/assets/background.jpeg);
  background-position: center;
  background-size: cover;
}

.glitch-image:nth-child(1) {
  opacity: 1;
}

.glitch-image:nth-child(2) {
	background-color: red;
	background-blend-mode: var(--blend-1);
}

.glitch-image:nth-child(3) {
	background-color: green;
	background-blend-mode: var(--blend-2);
}

.glitch-image:nth-child(4) {
	background-color: blue;
	background-blend-mode: var(--blend-3);
}

.glitch-image:nth-child(n+2) {
	opacity: 0;
}

.glitch-image:nth-child(n+1) {
	opacity: 1;
}

.glitch-image:nth-child(2) {
	transform: translate3d(1.1, 0, 0);
	animation: glitch-horizontal 3s infinite linear alternate;
}

.glitch-image:nth-child(3) {
	transform: translate3d(-1.1, 0, 0);
	animation: glitch-horizontal-2 2s infinite linear alternate;
}

.glitch-image:nth-child(4) {
	transform: translate3d(-1.1, 0, 0);
	animation: glitch-vertical 1s infinite linear alternate;
}

.glitch-image:nth-child(5) {
	transform: translate3d(0, 1.1, 0) scale3d(-1, -1, 1);
	animation: glitch-flash 0.5s steps(1, end) infinite;
}

.glitch-image:nth-child(6) {
	transform: translate3d(0, 1.3, 0) scale3d(-1, -1, 1);
	animation: glitch-flash-2 0.5s steps(1, end) infinite;
}

@keyframes glitch-horizontal {
	0% { 
		-webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
		clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
	}
	10% {
		-webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
		clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
	}
	20% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
		clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
	}
	30% {
		-webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
		clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
	}
	40% {
		-webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
		clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
	}
	50% {
		-webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
		clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
	}
	60% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
		clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
	}
	70% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
		clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
	}
	80% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
		clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
	}
	90% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
		clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
	}
	100% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
		clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
	}
}

@keyframes glitch-horizontal-2 {
	0% { 
		opacity: 1;
		transform: translate3d(-10px, 0, 0);
		-webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
		clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
	}
	3% {
		-webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
		clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
	}
	5% {
		-webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
		clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
	}
	7% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
		clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
	}
	9% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
		clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
	}
	11% {
		-webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
		clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
	}
	13% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
		clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
	}
	15% {
		-webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
		clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
	}
	17% {
		-webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
		clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
	}
	19% {
		-webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
		clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
	}
	20% {
		-webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
		clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
	}
	21.9% {
		opacity: 1;
		transform: translate3d(-10px, 0, 0);
	}
	22%, 100% {
		opacity: 0;
		transform: translate3d(0,0,0);
		-webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		clip-path: polygon(0 0, 0 0, 0 0, 0 0);
	}
}

@keyframes glitch-vertical {
	0% { 
		opacity: 1;
		transform: translate3d(0, -5px, 0) scale3d(-1,-1,1);
		-webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
		clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
	}
	1.5% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
		clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
	}
	2% {
		-webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
		clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
	}
	2.5% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
		clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
	}
	3% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
		clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
	}
	5% {
		-webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
		clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
	}
	5.5% {
		-webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
		clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
	}
	7% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
		clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
	}
	8% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
		clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
	}
	9% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
		clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
	}
	10.5% {
		-webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
		clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
	}
	11% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
		clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
	}
	13% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
		clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
	}
	14% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
		clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
	}
	14.5% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
		clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
	}
	15% {
		-webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
		clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
	}
	16% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
		clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
	}
	18% {
		-webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
		clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
	}
	20% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
		clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
	}
	21.9% {
		opacity: 1;
		transform: translate3d(0, -10px, 0) scale3d(-1,-1,1);
	}
	22%, 100% {
		opacity: 0;
		transform: translate3d(0,0,0);
		-webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		clip-path: polygon(0 0, 0 0, 0 0, 0 0);
	}
}

@keyframes glitch-flash {
	0% { 
		opacity: 0.2; 
		transform: translate3d(10px, 5px, 0);
	}
	33%, 100% { 
		opacity: 0;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes glitch-flash-2 {
	0% { 
		opacity: 0.2; 
		transform: translate3d(5px, 10px, 0);
	}
	13%, 100% { 
		opacity: 0;
		transform: translate3d(0, 0, 0);
	}
}
</style>